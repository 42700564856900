import {Directory, Filesystem} from '@capacitor/filesystem';
import {v4 as uuidv4} from 'uuid';
import {FileOnDisk, FileWriter} from './index';
import write_blob from 'capacitor-blob-writer';

export class GenericFileWriter implements FileWriter {
    public constructor(
        private readonly baseDirectory: string,
    ) {

    }

    async writeToDisk(fileName: string, data: Blob): Promise<FileOnDisk> {
        await Filesystem.requestPermissions();

        const uuid = uuidv4();
        await Filesystem.mkdir({
            path: `${this.baseDirectory}/${uuid}`,
            recursive: true,
            directory: Directory.Data,
        });

        await write_blob({
            path: `${this.baseDirectory}/${uuid}/${fileName}`,
            directory: Directory.Data,
            blob: data,
            fast_mode: true,
        });

        return this.fileOnDisk(`${this.baseDirectory}/${uuid}/${fileName}`, fileName);
    }

    async moveOntoDisk(path: string): Promise<FileOnDisk> {
        await Filesystem.requestPermissions();

        const fileName = path.split('/').pop()!;
        const uuid = uuidv4();

        await Filesystem.mkdir({
            path: `${this.baseDirectory}/${uuid}`,
            recursive: true,
            directory: Directory.Data,
        })

        await Filesystem.rename({
            from: path,
            to: `${this.baseDirectory}/${uuid}/${fileName}`,
            directory: Directory.Data,
        })

        return this.fileOnDisk(`${this.baseDirectory}/${uuid}/${fileName}`, fileName);
    }

    private async fileOnDisk(path: string, fileName: string): Promise<FileOnDisk> {
        const stat = await Filesystem.stat({
            path: path,
            directory: Directory.Data,
        });

        return {
            fileName: fileName,
            size: stat.size,
            sizeMb: stat.size / 1024 / 1024,
            nativePath: stat.uri,
            mimeType: stat.type,
        }
    }

    async clearStorage(): Promise<void> {
        try {
            await Filesystem.stat({
                path: this.baseDirectory,
                directory: Directory.Data,
            })
        } catch (e: any) {
            console.warn(e);
            await Filesystem.mkdir({
                path: this.baseDirectory,
                recursive: true,
                directory: Directory.Data,
            })
        }

        await Filesystem.rmdir({
            path: this.baseDirectory,
            directory: Directory.Data,
            recursive: true,
        });
    }
}
