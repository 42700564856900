import {PageWrapper} from '../../components/PageWrapper';
import {
    IonButton, IonCheckbox,
    IonGrid,
    IonImg,
    IonItem,
    IonLabel,
    IonListHeader,
    IonLoading,
    IonRow,
    IonThumbnail,
    IonTitle,
} from '@ionic/react';
import React from 'react';
import {StarSelector} from '../../components/StarSelector';
import {useLoginState} from '../../data/Login';
import {TemporaryImagePreview, TemporaryVideoPreview} from '../../components/TemporaryFilepreview';
import {ConditionsReport} from '../../data/ConditionsReport';
import {TemporaryVideo} from '../../data/videos';
import {ConditionsReportWithTemporaryMedia} from './Pro/conversion';
import {SubmissionError, submitConditionsReport} from '../../data/ConditionsReportSubmission';
import {ObservableTaskCollection} from '../../data/Task';
import {ObserverStatus} from '../../components/ObserverStatus';
import {Browser} from '@capacitor/browser';
import {environment} from '../../environment';
import {Alert} from '@mui/material';
import {useHistory} from 'react-router';
import {trimEnd, trimStart, useOnlineState} from '../../data/Helpers';
import {ErrorBox} from '../../components/ErrorBox';
import {useI18n} from '../../i18n/i18n';
import {TemporaryImage} from '../../data/images';
import { format } from 'date-fns'
import {fileWriterFactory} from "../../data/files/writer";
import {DebugOutput} from "../../components/DebugOutput";

interface SubmitGenericProps {
    cr: ConditionsReportWithTemporaryMedia|null,
    goBack: () => void,
    children?: React.ReactNode,
    deleteInProgressConditionsReport: () => Promise<void>,
}

export const SubmitGeneric: React.FunctionComponent<SubmitGenericProps> = (props) => {
    const history = useHistory();
    const {user} = useLoginState();
    const {label} = useI18n();

    const [tasks, setTasks] = React.useState<ObservableTaskCollection | null>(null);
    const [error, setError] = React.useState<SubmissionError | null>(null);
    const [isOnline] = useOnlineState();

    const submit = async () => {
        setError(null);
        const [tasks, start] = submitConditionsReport(props.cr!, user!.token, label);
        setTasks(tasks);

        const [cr, error] = await start();
        if (error) {
            console.error(error);
            setError(error);
        } else {
            // Additional logging added to debug error
            try {
                await fileWriterFactory.createFileWriterForConditionsReport(cr).clearStorage();
            } catch (e: unknown) {
                setError({
                    error: e,
                    userFacingMessage: 'temporaryFiles.clearStorage failed',
                    propertyErrors: [],
                });
            }

            try {
                let url = `${trimEnd(environment.powderguideWebsiteUrl, '/')}/conditions-report/${cr.uuid}`;
                if (cr.url) {
                    url = cr.url.indexOf('http') === 0 ? cr.url : `${trimEnd(environment.powderguideWebsiteUrl, '/')}/${trimStart(cr.url, '/')}`;
                }
                await Browser.open({url});
            } catch (e: unknown) {
                setError({
                    error: e,
                    userFacingMessage: 'Browser.open failed',
                    propertyErrors: [],
                });
            }

            try {
                await props.deleteInProgressConditionsReport();
            } catch (e) {
                setError({
                    error: e,
                    userFacingMessage: 'deleteInProgressConditionsReport failed',
                    propertyErrors: [],
                });
            }

            history.push('/');
        }

        setTasks(null);
    };

    let content: JSX.Element;
    if (tasks) {
        content = <div style={{paddingTop: '100px', paddingLeft: '15px', paddingRight: '15px'}}>
            <ObserverStatus observer={tasks}/>
        </div>;
    } else if (props.cr) {
        content = <ConditionsReportPreview conditionsReport={props.cr}/>;
    } else {
        content = <IonLoading/>;
    }

    let footer: React.ReactNode | null = null;
    if (props.cr && !tasks) {
        footer = <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div>
                <IonButton onClick={props.goBack}>{label('editing.action.back')}</IonButton>
            </div>
            <IonButton
                disabled={!isOnline}
                onClick={() => {
                    // console.log('submitting...');
                    // console.log(props.cr);
                    submit();
                }}
            >
                {label('submit.action.send')}
            </IonButton>
        </div>;
    }

    return <PageWrapper
        title={props.cr?.conditionsReport.title ?? 'Absenden'}
        showBackButton={true}
        onBack={props.goBack}
        footer={footer}
    >
        {error && <SubmissionErrorBox error={error}/>}
        {props.children}

        {content}

        <DebugOutput title="ConditionsReport" data={props.cr} />

        {!isOnline && <Alert severity="warning">
            <h2>{label('submit.error.no_internet.title')}</h2>
            <p>{label('submit.error.no_internet.message')}</p>
        </Alert>}
    </PageWrapper>;
};

interface ConditionsReportPreviewProps {
    conditionsReport: ConditionsReportWithTemporaryMedia;
}

const ConditionsReportPreview: React.FunctionComponent<ConditionsReportPreviewProps> = (props) => {
    const {label} = useI18n();
    const user = useLoginState().user;
    const cr = props.conditionsReport.conditionsReport;
    const temporaryImages = props.conditionsReport.temporaryImages;
    const temporaryVideos = props.conditionsReport.temporaryVideos;

    const formatDate = (date: string) => {
        return format(new Date(date), 'dd.MM.yyyy');
    }

    return <React.Fragment>
        <IonGrid>
            {
                cr.media.length > 0
                    ? <IonImg src={cr.media[0]}/>
                    : <TemporaryImagePreview image={temporaryImages[0]}
                                             bounds={{width: 800, height: 800}}/>
            }
        </IonGrid>

        <IonItem>
            <IonLabel>{label('cr.author')}</IonLabel>
            {user?.username}
        </IonItem>

        <IonItem>
            <IonLabel>{label('cr.location')}</IonLabel>
            {cr.spot.name}
        </IonItem>

        <IonItem>
            <IonLabel>{label('language')}</IonLabel>
            {label(`language.${cr.language}`)}
        </IonItem>

        <IonItem>
            <IonLabel>{label('cr.title')}</IonLabel>
            {cr.title}
        </IonItem>

        {cr.type === 'pro' && <React.Fragment>
            <IonItem>
                <IonLabel>{label('cr.exposition')}</IonLabel>
                {cr.exposition.map(e => label(`cr.exposition.${e}`)).join(', ')}
            </IonItem>
            <IonItem>
                <IonLabel>{label('cr.kind')}</IonLabel>
                {label(`cr.kind.${cr.activity}`)}
            </IonItem>
        </React.Fragment>}

        <IonItem>
            <IonLabel>{label('cr.date')}</IonLabel>
            {formatDate(cr.activity_date)}
        </IonItem>

        <IonItem>
            <IonLabel>{label('cr.overall_rating')}</IonLabel>
            <StarSelector value={cr.rating / 2} max={5}/>
        </IonItem>

        <IonItem>
            <IonLabel>{label('cr.description')}</IonLabel>
            {cr.description}
        </IonItem>

        {cr.type === 'pro' && <React.Fragment>
            <IonItem>
                <IonLabel>{label('cr.overall_trend')}</IonLabel>
                {label(`cr.overall_trend.${{ '-1': 'worse', 0: 'same', 1: 'better' }[cr.trend]}`)}
            </IonItem>

            <IonListHeader>
                <IonTitle>{label('cr.snow_condition')}</IonTitle>
                <StarSelector value={cr.snow_condition.rating / 2} max={5} />
            </IonListHeader>
            <IonItem>
                {cr.snow_condition.description ? cr.snow_condition.description : <i>{label('cr.snow_condition.description.no_value_provided')}</i>}
            </IonItem>
            <IonItem>
                <div className="ion-padding">
                    <CheckInformation checked={cr.snow_condition.descent_to_valley} text={label('cr.snow_condition.descent_to_valley')}>
                        {label('cr.snow_condition.descent_to_height', { descentToHeight: `${cr.snow_condition.descent_to_height}` })}
                    </CheckInformation>
                    <CheckInformation checked={cr.snow_condition.descent_through_forest} text={label('cr.snow_condition.descent_through_forest')} />
                    <CheckInformation checked={cr.snow_condition.blown_backs} text={label('cr.snow_condition.blown_backs')} />
                    <CheckInformation checked={cr.snow_condition.covered_shoes} text={label('cr.snow_condition.covered_shoes')} />
                    <CheckInformation checked={cr.snow_condition.variations_of_snow_cover} text={label('cr.snow_condition.variations_of_snow_cover')} />
                    <CheckInformation checked={cr.snow_condition.stone_contact_avoidable} text={label('cr.snow_condition.stone_contact_avoidable')} />
                </div>
            </IonItem>
            <IonItem>
                <IonLabel>{label('cr.snow_condition.fresh_snow')}</IonLabel>
                {cr.snow_condition.fresh_snow}cm
            </IonItem>

            <IonListHeader>
                <IonTitle>{label('cr.snow_quality')}</IonTitle>
                <StarSelector value={cr.snow_quality.rating / 2} max={5} />
            </IonListHeader>
            <IonItem>
                {cr.snow_quality.description ? cr.snow_quality.description : <i>{label('cr.snow_quality.description.no_value_provided')}</i>}
            </IonItem>
            <IonItem>
                <div className="ion-padding">
                    <CheckInformation checked={cr.snow_quality.old_snow} text={label('cr.snow_quality.old_snow')} />
                    <CheckInformation checked={cr.snow_quality.breakable_crust} text={label('cr.snow_quality.breakable_crust')} />
                    <CheckInformation checked={cr.snow_quality.slush} text={label('cr.snow_quality.slush')} />
                    <CheckInformation checked={cr.snow_quality.firn} text={label('cr.snow_quality.firn')} />
                    <CheckInformation checked={cr.snow_quality.wet_snow} text={label('cr.snow_quality.wet_snow')} />
                    <CheckInformation checked={cr.snow_quality.powder} text={label('cr.snow_quality.powder')} />
                    <CheckInformation checked={cr.snow_quality.summer_snow} text={label('cr.snow_quality.summer_snow')} />
                    <CheckInformation checked={cr.snow_quality.jelly_snow} text={label('cr.snow_quality.jelly_snow')} />
                    <CheckInformation checked={cr.snow_quality.wind_drifted} text={label('cr.snow_quality.wind_drifted')}>
                        {label('cr.altitude_lower')} {cr.snow_quality.wind_drifted_altitude_lower}m <br/>
                        {label('cr.snow_quality.wind_drifted_intensity')}: {label(`cr.snow_quality.wind_drifted_intensity.${cr.snow_quality.wind_drifted_intensity}`)} <br />
                        {label('cr.snow_quality.wind_drifted_exposition')}: {(cr.snow_quality.wind_drifted_exposition ?? [ ]).map(e => label(`cr.exposition.${e}`)).join(', ')}
                    </CheckInformation>
                </div>
            </IonItem>
        </React.Fragment>}

        <VideoList conditionsReport={cr} temporaryVideos={temporaryVideos}/>
        <ImageList conditionsReport={cr} temporaryImages={temporaryImages}/>
    </React.Fragment>;
};

interface CheckInformationProps {
    checked: boolean
    text: string
    children?: React.ReactNode
}

const CheckInformation: React.FunctionComponent<CheckInformationProps> = ({checked, text, children}) => {
    return <div style={{ display: 'flex', marginBottom: '.5em', alignItems: 'center' }}>
        <IonCheckbox
            disabled
            checked={checked}
            indeterminate={!checked}
            class="ion-margin-end"
            color={checked ? 'success' : 'medium'}
        />
        <div style={{ flex: '100' }}>
            {text}
            {checked && <React.Fragment><br /><i>{children}</i></React.Fragment>}
        </div>
        <br/>
    </div>
}

interface VideoPreviewProps {
    conditionsReport: ConditionsReport,
    temporaryVideos: TemporaryVideo[],
}

const VideoList: React.FunctionComponent<VideoPreviewProps> = (props) => {
    const {label} = useI18n();

    if (props.temporaryVideos.length === 0 && props.conditionsReport.videos.length === 0) {
        return null;
    }

    return <React.Fragment>
        <IonListHeader>
            <IonTitle>{label('cr.videos')}</IonTitle>
        </IonListHeader>
        {/*{props.conditionsReport.video.map(url => <IonItem key={url}>*/}
        {/*    <video src={url}/>*/}
        {/*</IonItem>)}*/}
        {props.temporaryVideos.map((video, index) => <IonItem key={index}>
            <IonLabel>{label('cr.video_num', {number: `${index + 1}`})}</IonLabel>
            <IonThumbnail>
                <TemporaryVideoPreview video={video}/>
            </IonThumbnail>
        </IonItem>)}
    </React.Fragment>;
};

interface MediaPreviewProps {
    conditionsReport: ConditionsReport,
    temporaryImages: TemporaryImage[],
}

const ImageList: React.FunctionComponent<MediaPreviewProps> = (props) => {
    const {label} = useI18n();

    if (props.temporaryImages.length === 0 && props.conditionsReport.media.length === 0) {
        return null;
    }

    return <React.Fragment>
        <IonListHeader>
            <IonTitle>{label('cr.images')}</IonTitle>
        </IonListHeader>
        <IonRow>
            {props.conditionsReport.media.map(media => <IonItem key={media}>
                <IonImg src={media}/>
            </IonItem>)}
            {props.temporaryImages.map((image, index) => <IonItem key={index} style={{width: '100%'}}>
                <IonLabel>{label('cr.image_num', {number: `${index + 1}`})}</IonLabel>
                <IonThumbnail>
                    <TemporaryImagePreview image={image}/>
                </IonThumbnail>
            </IonItem>)}
        </IonRow>
    </React.Fragment>;
};

interface SubmissionErrorBoxProps {
    error: SubmissionError,
}

const SubmissionErrorBox: React.FunctionComponent<SubmissionErrorBoxProps> = ({error}) => {
    return <React.Fragment>
        <ErrorBox error={error.error} title={error.userFacingMessage}>
            {error.propertyErrors.length > 0 && <ul>
                {error.propertyErrors.map((error, index) => <li key={index}>
                    <IonLabel>{error}</IonLabel>
                </li>)}
            </ul>}
        </ErrorBox>
        <DebugOutput title="Submission Error" data={error} />
    </React.Fragment>;
};
