import {fileHandling, FileOnDisk, FileWriter} from '../files';
import {implementationChooser} from '../Helpers';
import {NativeImageHandlingBackend} from './NativeImageHandlingBackend';
import {GenericImageHandlingBackend} from './GenericImageHandlingBackend';

export interface TemporaryImage extends FileOnDisk {
    rotation: number,
    width: number,
    height: number,
}

export interface ImageHandlingBackend {
    canLoadPhotoFromCamera(): Promise<boolean>;

    canLoadPhotoFromGallery(): Promise<boolean>;

    loadPhotoFromCamera(writer: FileWriter, maxSize: { width: number, height: number }): Promise<TemporaryImage | null>;

    loadPhotosFromGallery(writer: FileWriter, maxSize: { width: number, height: number }): Promise<TemporaryImage[]>;

    rotateImage(image: TemporaryImage): Promise<TemporaryImage>;
}

export const imageHandling = implementationChooser<ImageHandlingBackend>({
    ios: () => new NativeImageHandlingBackend(fileHandling),
    android: () => new NativeImageHandlingBackend(fileHandling),
    web: () => new GenericImageHandlingBackend(fileHandling),
});
