import {PickedFile} from '@capawesome/capacitor-file-picker';
import {GenericVideoHandlingBackend} from './GenericVideoHandlingBackend';
import {FileOnDisk, FileWriter} from '../files';
import {createVideoThumbnailUsingCapacitorPlugin} from "./helpers";

export class AndroidVideoHandlingBackend extends GenericVideoHandlingBackend {
    async canLoadVideosFromCamera(): Promise<boolean> {
        return true;
    }

    async canLoadVideosFromGallery(): Promise<boolean> {
        return true;
    }

    protected getVideoLength(file: PickedFile): Promise<number> {
        return Promise.resolve(file.duration!);
    }

    // Android has issues with the resulting URLs, therefor the data is read
    // and copied to memory instead of handled as path. This is not ideal memory
    // wise but the only way to get it working on Android.
    // See: https://github.com/capawesome-team/capacitor-plugins/issues/158
    // TODO: MAYBE THIS CAN BE MERGED WITH IOS
    protected async moveFileOntoDisk(writer: FileWriter, file: PickedFile): Promise<FileOnDisk> {
        return await writer.moveOntoDisk(file.path!);
    }

    protected async createVideoThumbnail(writer: FileWriter, path: string): Promise<FileOnDisk> {
        const thumbnail = await createVideoThumbnailUsingCapacitorPlugin(path);

        return this.fileHandling.getFile(thumbnail)
    }
}