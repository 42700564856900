import {VideoEditor} from "@awesome-cordova-plugins/video-editor";
import {FileOnDisk, FileWriter} from "../files";

export async function createVideoThumbnailUsingCapacitorPlugin(path: string): Promise<string> {
  const fileName = path.split('/').pop()!;

  return await VideoEditor.createThumbnail({
    fileUri: path,
    outputFileName: `${fileName}.thumbnail.jpg`,
    width: 320,
    height: 240,
  });
}

export async function createVideoThumbnailUsingHtmlVideoSnapshot(path: string, videoElement: HTMLVideoElement, writer: FileWriter): Promise<FileOnDisk> {
  const fileName = path.split('/').pop()!;
  const blob = await createThumbnailFromVideoElement(videoElement);

  return writer.writeToDisk(`${fileName}.thumbnail.jpg`, blob);
}

function createThumbnailFromVideoElement(videoElement: HTMLVideoElement): Promise<Blob> {
  return new Promise(resolve => {
    let retry = 5;
    let takingThumbnail = false;

    const timeupdate = async () => {
      if (takingThumbnail) {
        return;
      }

      takingThumbnail = true;
      const success = await attemptCreatingThumbnail();
      takingThumbnail = false;
      if (success) {
        return;
      }

      if (retry === 0) {
        throw 'Failed to create thumbnail';
      }

      retry--;
    };

    const attemptCreatingThumbnail = async () => {
      const thumbnail = await createVideoSnapshot(videoElement);
      if (!thumbnail) {
        return false;
      }
      videoElement.removeEventListener('timeupdate', timeupdate);
      videoElement.pause();
      resolve(thumbnail);

      return true;
    };

    videoElement.addEventListener('loadeddata', () => attemptCreatingThumbnail());
    videoElement.addEventListener('timeupdate', timeupdate);
    videoElement.play();
  });
}

function createVideoSnapshot(video: HTMLVideoElement): Promise<Blob | null> {
  video.width = video.videoWidth > 1000 ? video.videoWidth / 10 : video.videoWidth;
  video.height = video.videoWidth > 1000 ? video.videoHeight / 10 : video.videoHeight;
  const canvas = document.createElement('canvas');
  canvas.width = video.width;
  canvas.height = video.height;
  canvas.getContext('2d')!.drawImage(video, 0, 0, video.width, video.height);

  return new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg', 0.85));
}