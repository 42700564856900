import {GenericFileWriter} from "./GenericFileWriter";
import {MiniConditionsReport, ProConditionsReport} from "../ConditionsReport";
import {FileWriter} from "./index";
import {ID_NEW} from "../ConditionsReportProEditing";

export enum FileWriterType {
  ProConditionsReport = 'cr/pro',
  MiniConditionsReport = 'cr/mini',
}

export class FileWriterFactory {

  private instances: Record<string, FileWriter> = { };

  public createFileWriter(type: FileWriterType, subject: string): FileWriter {
    if (!this.instances[`${type}/${subject}`]) {
      this.instances[`${type}/${subject}`] = new GenericFileWriter(`${type}/${subject}`)
    }

    return this.instances[`${type}/${subject}`];
  }

  public createFileWriterForConditionsReport(cr: MiniConditionsReport | ProConditionsReport): FileWriter {
    return this.createFileWriter(
      cr.type === 'mini' ? FileWriterType.MiniConditionsReport : FileWriterType.ProConditionsReport,
      cr.uuid ?? ID_NEW,
    )
  }
}