import {fileHandling, FileOnDisk, FileWriter} from '../files';
import {implementationChooser} from '../Helpers';
import {AndroidVideoHandlingBackend} from './AndroidVideoHandlingBackend';
import {IosVideoHandlingBackend} from './IosVideoHandlingBackend';
import {WebVideoHandlingBackend} from './WebVideoHandlingBackend';

export interface TemporaryVideo extends FileOnDisk {
    thumbnail: FileOnDisk,
}

export class ValidationError extends Error {
    constructor(
        public readonly userFacingMessage: string,
    ) {
        super();
    }
}

export type ValidationFunction = (data: {
    name: string,
    sizeMb: number,
    lengthSeconds: number,
}) => ValidationError | null;

export interface VideoHandlingBackend {
    canLoadVideosFromCamera(): Promise<boolean>;

    loadVideosFromCamera(writer: FileWriter, validateVideo: ValidationFunction): Promise<TemporaryVideo[]>;

    canLoadVideosFromGallery(): Promise<boolean>;

    loadVideosFromGallery(writer: FileWriter, validateVideo: ValidationFunction): Promise<TemporaryVideo[]>;
}

export const videoHandling = implementationChooser<VideoHandlingBackend>({
    android: () => new AndroidVideoHandlingBackend(fileHandling),
    ios: () => new IosVideoHandlingBackend(fileHandling),
    web: () => new WebVideoHandlingBackend(fileHandling),
});