import React from 'react';
import {environment} from "../environment";
import {Alert} from "@mui/material";

interface DebugOutputProps {
  title: string;
  data: any;
}

export const DebugOutput: React.FC<DebugOutputProps> = props => {
  if (environment.isProduction) {
    return null;
  }

  return <div style={{ margin: '1em' }}>
    <Alert severity="warning">
      <details>
        <summary><h2 style={{ display: 'inline', paddingLeft: '10px' }}>Debug Output: {props.title}</h2></summary>
        <pre><code>{JSON.stringify(props.data, null, 4)}</code></pre>
      </details>
    </Alert>
  </div>
}