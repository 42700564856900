import {PickedFile} from '@capawesome/capacitor-file-picker';
import {GenericVideoHandlingBackend} from './GenericVideoHandlingBackend';
import {createVideoThumbnailUsingCapacitorPlugin} from "./helpers";
import {FileOnDisk, FileWriter} from "../files";

export class IosVideoHandlingBackend extends GenericVideoHandlingBackend {
    async canLoadVideosFromCamera(): Promise<boolean> {
        return true;
    }

    async canLoadVideosFromGallery(): Promise<boolean> {
        return true;
    }

    protected getVideoLength(file: PickedFile): Promise<number> {
        return Promise.resolve(file.duration!);
    }

    // Ios: The file path returned by pickVideos can be accessed directly.
    protected async moveFileOntoDisk(writer: FileWriter, file: PickedFile): Promise<FileOnDisk> {
        return await writer.moveOntoDisk(file.path!);
    }

    protected async createVideoThumbnail(writer: FileWriter, path: string): Promise<FileOnDisk> {
        const thumbnail = await createVideoThumbnailUsingCapacitorPlugin(path);

        return this.fileHandling.getFile(thumbnail)
    }
}