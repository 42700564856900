import {PickedFile} from '@capawesome/capacitor-file-picker';
import {GenericVideoHandlingBackend} from './GenericVideoHandlingBackend';
import {FileOnDisk, FileWriter} from '../files';
import {createVideoThumbnailUsingHtmlVideoSnapshot} from "./helpers";

export class WebVideoHandlingBackend extends GenericVideoHandlingBackend {
    async canLoadVideosFromCamera(): Promise<boolean> {
        return false;
    }

    async canLoadVideosFromGallery(): Promise<boolean> {
        return true;
    }

    protected async getVideoLength(file: PickedFile): Promise<number> {
        const publicPath = URL.createObjectURL(file.blob!);
        const video = await this.createVideoElement(publicPath)

        return video.duration;
    }

    // Web: File is not written to disk, but returned as a blob.
    //      We need to write the blob to disk ourselves.
    protected async moveFileOntoDisk(writer: FileWriter, file: PickedFile): Promise<FileOnDisk> {
        return writer.writeToDisk(file.name, file.blob!);
    }

    protected async createVideoThumbnail(writer: FileWriter, path: string, videoElement: HTMLVideoElement): Promise<FileOnDisk> {
        return createVideoThumbnailUsingHtmlVideoSnapshot(path, videoElement, writer);
    }
}