export enum Activity {
    Skitour = 'skitour',
    Freeride = 'freeride',
    Other = 'other',
}

export enum AvalancheType {
    DryLoose = 'dry_loose',
    WetLoose = 'wet_loose',
    DrySlab = 'dry_slab',
    WetSlab = 'wet_slab',
    Gliding = 'gliding',
    Wet = 'wet',
    CorniceBreak = 'cornice_break',
    Unknown = 'unknown',
}

export enum Exposition {
    North = 0,
    NorthEast = 1,
    East = 2,
    SouthEast = 3,
    South = 4,
    SouthWest = 5,
    West = 6,
    NorthWest = 7,
}

export enum ConditionsReportLanguage {
    German = 'de',
    English = 'en',
}

export enum AvalancheWarningLevel {
    Low = 1,
    Moderate = 2,
    Considerable = 3,
    High = 4,
    VeryHigh = 5,
}

export type LegacySnowQualityReview = {
    rating: number,
    description: string,
    attributes: {
        old_snow: boolean,
        breakable_crust: boolean,
        slush: boolean,
        firn: boolean,
        wet_snow: boolean,
        powder: boolean,
        summer_snow: boolean,
        jelly_snow: boolean,
        wind_drifted: boolean,
        wind_drifted_attrs?: {
            exposition: Exposition[],
            altitude_upper?: number,
            altitude_lower?: number,
            intensity: number,
            description: string,
        }
    }
}

export type SnowConditionReview = {
    rating: number,
    description: string,
    attributes: {
        descent_to_valley: boolean,
        descent_to_height: number,
        descent_through_forest: boolean,
        blown_backs: boolean,
        covered_shoes: boolean,
        variations_of_snow_cover: boolean,
        stone_contact_avoidable: boolean,
        fresh_snow: number,
    }
}

export type LegacyRiskReview = {
    rating: number,
    description: string,
    attributes: {
        no_warning_signs: boolean,
        abort: boolean,
        cracking: boolean,
        fresh_avalanches: boolean,
        rockfall: boolean,
        crevices: boolean,
        diurnal_warming: boolean,
        much_fresh_snow: boolean,
        much_water: boolean,
        hissing_noise: boolean,
        old_snow_problem: boolean,
        wind_direction: Exposition[],
        wind_intensity: number,
        fresh_avalanches_attrs?: {
            exposition: Exposition,
            altitude: number,
            size: number,
            type: AvalancheType,
            description: string,
        }
    }
}

export type LegacyOverallReview = {
    rating: number,
    description: string,
    attributes: {
        trend: number,
        index_of_first_image: number,
    }
}

export enum VideoType {
    CloudFlare = 'cloudflare',
}

export interface VideoInformation {
    type: VideoType,
    external_id: string,
}

export interface ConditionsReportSnowQuality {
    rating: number,
    description: string,
    old_snow: boolean,
    breakable_crust: boolean,
    slush: boolean,
    firn: boolean,
    wet_snow: boolean,
    powder: boolean,
    summer_snow: boolean,
    jelly_snow: boolean,
    wind_drifted: boolean,
    wind_drifted_exposition: Exposition[]|null,
    wind_drifted_altitude_upper?: number|null,
    wind_drifted_altitude_lower?: number|null,
    wind_drifted_intensity: number|null,
    wind_drifted_description: string|null,
}
export interface ConditionsReportSnowCondition {
    rating: number,
    description: string,
    descent_to_valley: boolean,
    descent_to_height: number,
    descent_through_forest: boolean,
    blown_backs: boolean,
    covered_shoes: boolean,
    variations_of_snow_cover: boolean,
    stone_contact_avoidable: boolean,
    fresh_snow: number,
}
export interface ConditionsReportRisk {
    rating: number,
    description: string,
    no_warning_signs: boolean,
    abort: boolean,
    cracking: boolean,
    fresh_avalanches: boolean,
    rockfall: boolean,
    crevices: boolean,
    diurnal_warming: boolean,
    much_fresh_snow: boolean,
    much_water: boolean,
    hissing_noise: boolean,
    old_snow_problem: boolean,
    wind_direction: Exposition[],
    wind_intensity: number,
    avalanche_exposition: Exposition|null,
    avalanche_altitude: number|null,
    avalanche_size: number|null,
    avalanche_type: AvalancheType|null,
    avalanche_description: string|null,
}

export interface MiniConditionsReport {
    type: 'mini',
    uuid: string|null,
    language: ConditionsReportLanguage,
    title: string,
    activity_date: string,
    rating: number,
    description: string,
    media: string[],
    videos: VideoInformation[],
    spot: {
        uuid: string,
        name: string,
    },
}

export interface ProConditionsReport {
    type: 'pro'
    uuid: string|null,
    language: ConditionsReportLanguage,
    title: string,
    activity_date: string,
    activity: Activity,
    rating: number,
    description: string,
    trend: number,
    media: string[],
    videos: VideoInformation[],
    exposition: Exposition[],
    avalanche_warning_level: AvalancheWarningLevel,
    altitude_lower: number,
    altitude_upper: number,
    spot: {
        uuid: string,
        name: string,
    },
    snow_quality: ConditionsReportSnowQuality,
    snow_condition: ConditionsReportSnowCondition,
    risk: ConditionsReportRisk,
}

export type ConditionsReport = MiniConditionsReport | ProConditionsReport;

export type ProConditionsReportWithLegacyInformation = Omit<ProConditionsReport, 'risk'> & {
    video: string | null,
    snowquality: LegacySnowQualityReview,
    snowcondition: SnowConditionReview,
    risk: ConditionsReportRisk&LegacyRiskReview,
    overall: LegacyOverallReview,
}
