import React, {useEffect} from 'react';
import {
    IonButton,
    IonDatetime,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonTextarea,
} from '@ionic/react';
import {Modal} from '../../../components/modal/Modal';
import {LocationSelectModal} from '../../../components/modal/LocationSelectModal';
import {LocationCreateModal} from '../../../components/modal/LocationCreateModal';
import {StarSelector} from '../../../components/StarSelector';
import {VideoPicker} from '../../../components/VideoPicker';
import {ImagePicker} from '../../../components/ImagePicker';
import {IonItemWithValidation} from '../../../components/ValidationWarning';
import {ConditionsReportMiniValidationData} from "../../../data/ConditionsReportMiniEditing";
import {useLoginState} from '../../../data/Login';
import {ShortSpot} from '../../../data/repository/Spot';
import {useOnlineState} from '../../../data/Helpers';
import {TemporaryVideo} from '../../../data/videos';
import {TemporaryImage} from '../../../data/images';
import {addOutline, createOutline} from 'ionicons/icons';
import {LabelFunc, useI18n} from "../../../i18n/i18n";
import {format} from 'date-fns';
import {Alert} from '@mui/material';
import {FileWriter} from "../../../data/files";

export interface ConditionsReportMiniFormData {
  title: string,
  hasCustomizedTitle: boolean,
  location: {
      uuid: string,
      name: string,
  },
  date: string,
  rating: number,
  description: string,
  videos: TemporaryVideo[],
  images: TemporaryImage[],
}

export function emptyFormData(): ConditionsReportMiniFormData {
  return {
    title: '',
    hasCustomizedTitle: false,
    location: {
        uuid: '',
        name: '',
    },
    date: (new Date()).toISOString().substring(0, 10),
    rating: 0,
    description: '',
    videos: [],
    images: [],
  }
}
export function validate(formData: ConditionsReportMiniFormData, label: LabelFunc): ConditionsReportMiniValidationData {
  const validation: ConditionsReportMiniValidationData = {};

  if (formData.location.uuid === '') {
      validation.location = [label('mr.location.validation.exists')];
  }
  if (!formData.date) {
      validation.date = [label('mr.date.validation.exists')];
  }
  if (formData.rating === 0) {
    validation.rating = [label('mr.rating.validation.exists')];
  }
  if (formData.images.length === 0) {
    validation.images = [label('image_picker.info.minimum_required')];
  }

  return validation;
}

interface ConditionsReportMiniFormProps {
  fileWriter: FileWriter,
  formData: ConditionsReportMiniFormData,
  updateFormData: (data: Partial<ConditionsReportMiniFormData>) => Promise<void>,
  validation: ConditionsReportMiniValidationData,
}

export const ConditionsReportMiniForm: React.FunctionComponent<ConditionsReportMiniFormProps> = (props) => {
    const {label} = useI18n();
    const {formData, validation, updateFormData} = props;
    const {user} = useLoginState();

    const [showLocationSelector, setShowLocationSelector] = React.useState(false);
    const [showLocationCreator, setShowLocationCreator] = React.useState(false);
    const [online] = useOnlineState();
    const [today] = React.useState<Date>(new Date());

    const selectLocation = (spot: ShortSpot | null) => {
        if (spot) {
            updateFormData({location: {uuid: spot.uuid, name: spot.title}});
        } else {
            updateFormData({location: {uuid: '', name: ''}});
        }
        setShowLocationSelector(false);
        setShowLocationCreator(false);
    };

    // Automatic filling of the title
    useEffect(() => {
        if (formData.hasCustomizedTitle) {
            return;
        }
        if (formData.location.name && formData.date && user?.username) {
            const formattedDate = format(new Date(formData.date), 'dd.MM.yyyy');
            updateFormData({title: `${formData.location.name} am ${formattedDate} | ${user.username ?? ''}`});
        }
    }, [formData.location.name, formData.date, user]);

    return <React.Fragment>
        <IonList>
            <IonItemWithValidation errors={validation.location}>
                <IonButton slot="start" color="primary" onClick={() => setShowLocationSelector(true)}>
                    {label('mr.location.select')}*
                </IonButton>
                <div>
                    <IonInput
                        placeholder={label('mr.location') + '...'}
                        value={formData.location.name}
                        readonly
                        onClick={() => setShowLocationSelector(true)}
                    />
                </div>
            </IonItemWithValidation>

            <IonItem lines="none">
                <IonLabel>{label('mr.location.select_or_add')}</IonLabel>
            </IonItem>
            <IonItem>
                <IonButton slot="start" color="primary" onClick={() => setShowLocationCreator(true)} disabled={!online}>
                    <IonIcon icon={addOutline} slot="start"/>
                    {label('mr.location.add')}
                </IonButton>
            </IonItem>

            <IonItemWithValidation errors={validation.date}>
                <IonLabel>{label('mr.date')}*</IonLabel>
                <IonDatetime
                    value={formData.date}
                    onIonChange={(e: any) => updateFormData({date: e.detail.value})}
                    locale="de-DE"
                    presentation="date"
                    max={today.toISOString().substring(0, 10)}
                />
            </IonItemWithValidation>

            <IonItemWithValidation errors={validation.title}>
                <IonTextarea
                    value={formData.title}
                    onIonInput={e => updateFormData({title: e.detail.value!})}
                    onBlur={
                        e => {
                            const customized = e.target.value ? e.target.value.trim().length > 0 : false;
                            if (customized) {
                                updateFormData({hasCustomizedTitle: true});
                            } else {
                                if (formData.location.name && formData.date && user?.username) {
                                    const formattedDate = format(new Date(formData.date), 'dd.MM.yyyy');
                                    updateFormData({
                                        title: `${formData.location.name} am ${formattedDate} | ${user.username ?? ''}`,
                                        hasCustomizedTitle: false,
                                    });
                                }
                            }
                        }
                    }
                    autocapitalize="sentences"
                >
                    <IonLabel slot="label">
                        <IonIcon icon={createOutline}/>
                        {label('mr.title')}
                    </IonLabel>
                </IonTextarea>
            </IonItemWithValidation>

            <IonItemWithValidation errors={validation.rating}>
                <IonLabel>Gesamteindruck*:</IonLabel>
                <StarSelector value={formData.rating} onChange={rating => updateFormData({rating: rating})}
                            max={5}/>
            </IonItemWithValidation>

            <IonItemWithValidation errors={validation.description}>
                <IonTextarea
                    placeholder={label('cr.description')}
                    value={formData.description}
                    onIonInput={e => updateFormData({description: e.detail.value!})}
                    autocapitalize="sentences"
                    label="Text"
                />
            </IonItemWithValidation>

            <VideoPicker
                writer={props.fileWriter}
                max={3}
                maxSizeMb={200}
                maxLengthS={30}
                videos={formData.videos}
                onChange={videos => updateFormData({videos})}
            />

            <ImagePicker
                writer={props.fileWriter}
                maxWidthPx={2000}
                maxHeightPx={2000}
                maxSizeMb={10}
                images={formData.images}
                onChange={images => updateFormData({images})}
            />
            {formData.images.length === 0 && <Alert className="ion-margin" severity={validation ? 'warning' : 'info'}>
                {label('image_picker.info.minimum_required')}
            </Alert>}

        </IonList>

        <Modal open={showLocationSelector} onClose={() => setShowLocationSelector(false)}>
            <LocationSelectModal
                location={formData.location.uuid}
                onDismiss={() => setShowLocationSelector(false)}
                onSelect={(uuid, spot) => selectLocation(spot)}
            />
        </Modal>
        <Modal open={showLocationCreator} onClose={() => setShowLocationCreator(false)}>
            <LocationCreateModal onDismiss={() => setShowLocationCreator(false)}
                                 onCreate={spot => selectLocation(spot)}/>
        </Modal>
    </React.Fragment>;
};
