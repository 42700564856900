import {Storage} from '@ionic/storage';
import {StepData} from './ConditionsReportProEditing';
import {ConditionsReportMiniFormData} from "../pages/CreationForm/Mini/Form";
import React from "react";

const MINI_PREFIX = 'conditions_report_mini_edit.';
const PRO_PREFIX = 'conditions_report_edit.';

export interface ConditionsReportListItem<TType = 'mini'|'pro'> {
    type: TType,
    id: string,
    data: Partial<TType extends 'pro' ? StepData : ConditionsReportMiniFormData>
}

type ConditionsReportEditingListener<TType = 'mini'|'pro'> = (context: ConditionsReportListItem<TType>[]) => void;

class ConditionsReportEditingStorage {

    private listeners: Record<string, ConditionsReportEditingListener> = { };

    private storage: Storage;

    constructor() {
        this.storage = new Storage();
        this.storage.create();
    }

    async loadMiniFormData(id: string): Promise<Partial<ConditionsReportMiniFormData>> {
        const key = MINI_PREFIX + id;
        const result = await this.storage.get(key) as Partial<ConditionsReportMiniFormData> | null;

        return result ?? {};
    }

    async storeMiniFormData(id: string, data: ConditionsReportMiniFormData): Promise<void> {
        const key = MINI_PREFIX + id;
        await this.storage.set(key, data);
        this.callListeners();
    }

    async removeMiniFormData(id: string): Promise<void> {
        const key = MINI_PREFIX + id;
        await this.storage.remove(key);
        this.callListeners();
    }

    async loadProStepData(id: string): Promise<Partial<StepData>> {
        const key = PRO_PREFIX + id;
        const result = await this.storage.get(key) as Partial<StepData> | null;

        return result ?? {};
    }

    async storeProStepData(id: string, data: StepData): Promise<void> {
        const key = PRO_PREFIX + id;
        await this.storage.set(key, data);
        this.callListeners();
    }

    async removeProStepData(id: string): Promise<void> {
        const key = PRO_PREFIX + id;
        await this.storage.remove(key);
        this.callListeners();
    }

    listen(listener: ConditionsReportEditingListener): string {
        const id = Math.random().toString(36).substring(7);
        this.listeners[id] = listener;

        this.callListeners();

        return id;
    }

    removeListener(id: string): void {
        delete this.listeners[id];
    }

    private callListeners(): void {
        this.storage.keys().then(async keys => {
            const changes: ConditionsReportListItem[] = [ ];

            for (const key of await keys) {
                if (key.startsWith(PRO_PREFIX)) {
                    const id = key.replace(PRO_PREFIX, '');
                    changes.push({ type: 'pro', id, data: await this.loadProStepData(id) });
                } else if (key.startsWith(MINI_PREFIX)) {
                    const id = key.replace(MINI_PREFIX, '');
                    changes.push({ type: 'mini', id, data: await this.loadMiniFormData(id) });
                }
            }

            for (const listener of Object.values(this.listeners)) {
                listener(changes);
            }
        })
    }
}

export const conditionsReportEditingStorage = new ConditionsReportEditingStorage();


export function useInProgressConditionsReports() {
    const [ allCrs, setAllCrs ] = React.useState<ConditionsReportListItem[]>([ ]);
    const [ crs, setCrs ] = React.useState<{ mini: ConditionsReportListItem<'mini'>[], pro: ConditionsReportListItem<'pro'>[] }>({ mini: [], pro: [] });
    const [ newCr, setNewCr ] = React.useState<{ mini: ConditionsReportListItem<'mini'>|null, pro:ConditionsReportListItem<'pro'>|null }>({ mini: null, pro: null })

    React.useEffect( () => {
        const listenerId = conditionsReportEditingStorage.listen(setAllCrs)

        return () => conditionsReportEditingStorage.removeListener(listenerId);
    }, [ ])

    React.useEffect(() => {
        const crs: { mini: ConditionsReportListItem<'mini'>[], pro: ConditionsReportListItem<'pro'>[] } = { mini: [], pro: [] };
        const newCrs: { mini: ConditionsReportListItem<'mini'>|null, pro: ConditionsReportListItem<'pro'>|null } = { mini: null, pro: null };
        for (const cr of allCrs) {
            crs[cr.type].push(cr as any);
            if (cr.id === 'new') {
                newCrs[cr.type] = cr as any;
            }
        }

        setCrs(crs);
        setNewCr(newCrs);
    }, [ allCrs ]);

    return { allCrs, crs, newCr };
}