import {Camera} from '@capacitor/camera';
import {TemporaryImage} from '.';
import {GenericImageHandlingBackend} from './GenericImageHandlingBackend';
import { FileWriter} from "../files";

export class NativeImageHandlingBackend extends GenericImageHandlingBackend {
    async loadPhotoFromCamera(writer: FileWriter, maxSize: { width: number; height: number; }): Promise<TemporaryImage | null> {
        await Camera.requestPermissions({permissions: ['camera']}).catch(e => console.warn(e));
        return super.loadPhotoFromCamera(writer, maxSize);
    }

    async loadPhotosFromGallery(writer: FileWriter, maxSize: { width: number; height: number; }): Promise<TemporaryImage[]> {
        await Camera.requestPermissions({permissions: ['photos']});
        return super.loadPhotosFromGallery(writer, maxSize);
    }
}